html, body, #root {
    height: 100%;
}

section {
    min-height: 100%!important;
}

.site-page-header {
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
}

.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
}
