@use 'styles/variables' as variables;

.wrapper {
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 0;

    span {
        font-size: 16px;
        line-height: 16px;
    }

    .iconWrapper {
        &.low {
            transform: rotate(180deg);
            color: variables.$lowColorKpi;
        }
        &.high {
            transform: rotate(0deg);
            color: variables.$highColorKpi;
        }
    }
}