@use 'styles/variables.scss' as v;

.wrapper{
    display: flex;
    justify-content:center;
    align-items: center;
    height: 100vh;
    background-image: url('../../../public/bg.jpg');
    background-size: 100% 100%;
    flex-direction: column;
    
    & > div {
        display: flex;
        justify-content:center;
        align-items: center;
        flex-direction: column;
        background-color: v.$loginContainerBgColor;
        padding: 24px;
        border-radius: 8px;
        
        img {
            width: 200px;
            margin-bottom: 36px;
        }
    }
}

.loginButton {
  width: 100%;
}

.logo {
  width: 180px;
  margin-bottom: 24px;
}
