@use "styles/variables" as variables;

.customCard {
    height: 100%;
    p {
        margin-bottom: 0;
    }
    p:first-child,
    .dateText {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &.high {
        p:last-child {
            color: variables.$highColorKpi;
        }
    }

    &.low {
        p:last-child {
            color: variables.$lowColorKpi;
        }
    }

    .dateText {
        font-size: 0.8em;
        position: relative;
        bottom: 2px;
        margin-bottom: -12px;
    }
}
