.wrapper {
    position: absolute;
    top: 18px;
    right: 24px;
    text-align: right;

    & > div {
        margin-bottom: 5px;
    }

    .link {
        display: none;
    }
}
