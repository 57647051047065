.titleWrapper {
    display: flex;
    align-items: center;

    .goBackArrow svg {
        margin-right: 16px;
    }

    button {
        margin-left: 0.5em;
    }

    h4 {
        margin-bottom: 0px;
        margin-top: 0px;
    }
}
.breadcrumbWrapper {
    margin-bottom: 12px;
}
.extraWrapper {
    float: right;

    & > * {
        margin-left: 1em;
    }
}

.wrapper {
    display: flex;
    justify-content: space-between;
    min-height: 80px;
}

.ghostWrapper {
    padding: 16px 24px;
}
