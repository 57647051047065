@use "styles/variables" as variables;

.wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px !important;

    .wrapperTable {
        color: variables.$statusTableBg;
        margin-left: auto;

        td {
            padding: 2.5px;
        }
    }

    .innerTable {
        display: flex;
        flex-wrap: wrap;
        height: 60px;
        flex-direction: column;

        & > div {
            flex: 1 1 50%;
            display: flex;
            min-width: 80px;
            margin-left: 10px;

            & > span:first-child {
                flex-grow: 1;
                margin-right: 5px;
            }
        }
    }

    .innerTable:first-child {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .column {
            display: flex;
            flex-direction: column;
            flex: 1;
            min-width: auto;
            margin-left: 10px;
        }

        .columnRow {
            display: flex;
            justify-content: flex-end;

            span:first-child {
                white-space: nowrap;
                margin-right: 5px;
            }
        }
    }
}
