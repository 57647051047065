$primaryColor: #004f86;

$white: #ffffff;
$green: #87d068;
$grey: #999999;
$red: red;
$orange: #f57c01;

$loginContainerBgColor: $white;
$activeColorPill: $green;
$inactiveColorPill: $grey;
$highColorKpi: $green;
$lowColorKpi: $red;
$statusTableBg: $grey;
$mapMarkerColor: $orange;

$headerHeight: 79px;
