.positiveSavings {
    color: green;
}

.negativeSavings {
    color: red;
}

.arrowUp {
    color: green;
}

.arrowDown {
    color: red;
    transform: rotate(180deg);
}
.noDataValue {
    color: grey;
}
