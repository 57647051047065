@use 'styles/variables.scss' as v;

.wrapper {
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: v.$white;
    padding: 0 8px;
    cursor: pointer;
    height: 20px;

    &.active {
        background: v.$activeColorPill;
    }

    &.inactive {
        background: v.$inactiveColorPill;
    }
}
