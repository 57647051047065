@use 'styles/variables' as variables;

.map {
    height: calc(100vh - 64px - 2em);
}

.markerWrapper {
    background-color: variables.$mapMarkerColor;
    border: variables.$mapMarkerColor 7px solid;
    border-radius: 50%;
    padding: 0.5em;
    path {
        fill: white;
    }
}
