@import '../../styles/variables';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white!important;
    height: 65px;

    & > a {
        display: flex;
        align-items: center;
    }
    img {
        width: 90px;
        height: auto;
    }

    .badge {
        margin-right: 20px;
    }
}

.site-page-header {
    border: 1px solid rgb(235, 237, 240);
    height: $headerHeight;
}

